<template>
  <div class="administrator-wrapper">
    <Breadcrumb/>
    <div class="subtitle has-text-weight-semibold">{{ administrator.email || '&nbsp;'}}</div>
    <b-field label="Corporation">
      <b-select placeholder="Select a corporation" v-model="selectedCorporationId" @input="didSelectCorporation" :disabled="administrator.corporations.length === 0">
        <option
          v-for="corp in administrator.corporations"
          :value="corp.id"
          :key="corp.id">
          {{ corp.name }}
        </option>
      </b-select>
    </b-field>
    <div v-if="selectedCorporationId">
      <b-table :data="resources" hoverable>
        <b-table-column field="label" label="Access Control" v-slot="props">{{ props.row.label }}</b-table-column>
        <b-table-column v-for="value in actions" :key="value" field="label" :label="value" :centered="true" v-slot="props">
          <b-checkbox
            @input='onUpdateCheckbox($event, props.row.key, value.toLowerCase())'
            :value="props.row.permissions.includes(value.toLowerCase())"
            :disabled="!corporationPermissions || isSaving"
            class="mr-0 has-no-text"
          />
        </b-table-column>
      </b-table>
      <b-button @click="savePermissions" :disabled="!corporationPermissions || isSaving" :class="`has-background-blue mt-5 ${isSaving ? 'is-loading' : ''}`">Save</b-button>
    </div>
    <Flash title="Congrats" :content="flashContent" :show="!!flashContent" :hideFlash="hideFlash"></Flash>
  </div>
</template>
<script>
import {
  fetchAdministrator,
  fetchAdminCorporationPermissions,
  updateAdminCorporationPermissions
} from '@api/addy-plus/account-administrators'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import { camelToSnakeCase } from '@utils/common-methods/common'
import Flash from '@components/modal/slide-down-flash.vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'

export default {
  title: 'Account Administrators: Permissions',
  mixins: [pageTitleMixin],
  components: {
    Flash,
    Breadcrumb,
  },
  data() {
    return {
      actions: Object.freeze(['Create', 'Read', 'Update', 'Delete']),
      administrator: {
        email: null,
        corporations: [],
      },
      resources: [
        { key: 'entity', label: 'Entity', permissions: [] },
        { key: 'entityDataRoom', label: 'Data Room', permissions: [] },
        { key: 'entityAssets', label: 'Assets', permissions: [] },
        { key: 'entityCsr', label: 'CSR', permissions: [] },
        { key: 'entityExecutives', label: 'Officers & Directors', permissions: [] },
        { key: 'entityWallet', label: 'Wallet', permissions: [] },
        { key: 'entityDistributions', label: 'Distributions', permissions: [] },
        { key: 'asset', label: 'Asset', permissions: [] },
        { key: 'assetUpdates', label: 'Asset Updates', permissions: [] },
        { key: 'assetDueDiligence', label: 'Asset Due Diligence', permissions: [] },
      ],
      flashContent: '',
      timer: null,
      isSaving: false,
      corporationsList: [],
      selectedCorporationId: null,
      corporationPermissions: null,
    }
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    },
    adminId() {
      return this.$route.params.adminId
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      fetchAdministrator(this.accountId, this.adminId).then((result) => {
        if (!result.success) return
        this.administrator = result.data
      })
    },
    didSelectCorporation(corporationId) {
      fetchAdminCorporationPermissions(this.accountId, this.adminId, corporationId).then((result) => {
        if (!result.success) return
        this.corporationPermissions = result.data.corporationPermissions
        this.resources.forEach((resource) => {
          resource.permissions = this.corporationPermissions[resource.key]
        })
      })
    },
    onUpdateCheckbox(value, key, permission) {
      const resource = this.resources.find((res) => res.key === key)

      if (!resource) return

      if (value) {
        resource.permissions.push(permission)
      } else {
        resource.permissions.splice(resource.permissions.indexOf(permission), 1)
      }
    },
    savePermissions() {
      this.isSaving = true
      const payload = {}
      this.resources.forEach((resource) => {
        payload[camelToSnakeCase(resource.key)] = resource.permissions
      })
      updateAdminCorporationPermissions(this.accountId, this.adminId, { corporation_id: this.selectedCorporationId, permissions: payload }).then((result) => {
        this.isSaving = false
        if (!result.success) return

        this.flashContent = result.data.message

        this.timer && clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.hideFlash()
        }, 4000)
      })
    },
    hideFlash() {
      this.flashContent = ''
    },
  },
}
</script>
<style lang="scss" scoped>
.administrator-wrapper {
  font-size: 16px;
}
</style>
